<template>
    <!-- 单品作品 -->
    <div class="box">
        <div class="box-content flex flex-v">
            <div class="list flex-1" ref="load" style="height:100%;overflow-y:auto;">
                <div v-if="list && list.length>0">
                    <div v-for="(item,idx) in list" :key="idx" class="list-item">
                        <div class="list-item-top flex">
                            <div @click="gotoDetail(item.id)" style="width:180px;height:180px;margin-right:12px;">
                                <div v-if="item.photos && item.photos.length>0" class="bg_img"
                                    :style="{backgroundImage: 'url(' + IMG_URL_PRE+item.photos[0]+'!width_250px' + ')'}"></div>
                            </div>
                            <div class="lit-right flex-1">
                                <h3 v-if="item.name" @click="goto('/factory/qygl/detail/'+item.trans_no)" class="item-name ellipsis2">{{item.name}}</h3>
                                <div class="item-series flex">
                                    <span v-if="item.style">{{item.style.name}}</span>
                                    <span v-if="item.style && item.cat" style="margin:0 4px"> | </span>
                                    <span v-if="item.cat">{{item.cat.name}}</span>
                                </div>
                                <div class="item-time">发起时间：{{item.created_at | moment('YYYY/MM/DD HH:mm:ss')}}</div>
                                <div class="item-price">
                                    <span>价格：</span>
                                    <span class="price">￥<span>{{item.price | priceFilt}}</span></span>
                                </div>
                                <div class="item-avatar flex flex-align-center">
                                    <div class="avatar">
                                        <div v-if="item.design_comp && item.design_comp.logo" class="bg_img"
                                            :style="{backgroundImage: 'url(' + IMG_URL_PRE+item.design_comp.logo + ')'}"></div>
                                        <div v-else class="bg_img"
                                            :style="{backgroundImage: 'url(' + staticUrl+'design/placeholder/zhanweilogo%402x.jpg!width_250px' + ')'}"></div>
                                    </div>
                                    <div v-if="item.design_comp">{{item.design_comp.name}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="list-item-bottom">
                            <div v-if="item.trans_stat<0">
                                <div style="text-align:center;font-size:32px;padding:10px 0;">暂无工厂签约</div>
                            </div>
                            <div v-if="item.trans_stat>=0">
                                <SignPlan v-if="item && item.id" :item="item" :planType="'bq_order'" @refresh="refreshItems"></SignPlan>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div v-if="list && list.length==0" class="flex-1 flex flex-center" style="text-align:center;height:100%">
                    <div>
                        <img style="width:110px" :src="staticUrl+'/design/placeholder/meiyougengduo.png'" alt="">
                        <div style="font-size:16px;color:#808080;padding:15px 0;">暂无相关数据</div>
                    </div>
                </div>
            </div>
            <div v-if="pageShow" class="pagination-wrap flex">
                <el-pagination background @current-change="currentChange"
                    layout="prev, pager, next"
                    :current-page.sync="opt.page"
                    :page-size="opt.pagesize"
                    :total="total"></el-pagination>
            </div>
        </div>
        
    </div>
</template>

<script>
    import { IMG_URL_PRE,STATIC_URL_PRE } from '@/config';
    import { designSetOrder } from '@/service/factory';
    import SignPlan from '@/components/tradeOrder/Buyer.vue';
    export default {
        components: { SignPlan },
        props:['query'],
        data() {
            return {
                IMG_URL_PRE,
                staticUrl:STATIC_URL_PRE,
                popVisible: false,
                popTitle: null,
                opt: {
                     k: null,
                    style_no: null, // 风格编号列表
                    // cat_id: null,
                    stat: null, // 状态列表，0已创建，1买方发起签合同，2买方已签合同，3卖方发起签合同，4卖方已签合同，5买方已付款，6卖方确认收款，7卖方已发货，8买方已收货，9买方已评价，10卖方已评价，11交易完成，21> 卖方已修改商品信息，22库存不足
                    sort_by: "", // 默认按发布时间排序，可以传price按价格排序
                    sort_order: "", // 默认降序，传1表示升序
                    page: 1,
                    pagesize: 10,
                },
                total:0,
                pageShow:false, // 强制刷新分页组件
                list: null,
            }
        },
        watch: {
            $route(to,from){
                console.log("to",to);
                this.opt.k = this.$route.query.k;
                this.opt.style_no = [this.$route.query.style_no];
                if (this.$route.query.stat==6) {
                    this.opt.stat = [6,11];
                } else if (this.$route.query.stat==1) {
                    this.opt.stat = [0,1];
                } else {
                    this.opt.stat = [this.$route.query.stat];
                }
                this.opt.page=parseInt(this.$route.query.page);
                this.refreshItems();
                this.$nextTick(()=>{
                    this.pageShow = true;
                })
            }
        },
        created() {
            // if (this.query.k) {
            //     this.opt.k = this.query.k;
            // }
            // if (this.query.style_no) {
            //     this.opt.style_no = [this.query.style_no];
            // }
            // if (this.query.stat==6) {
            //     this.opt.stat = [6,11];
            // } else if (this.query.stat==1) {
            //     this.opt.stat = [0,1];
            // } else {
            //     this.opt.stat = [this.query.stat];
            // }
            if (this.query.sort_by) {
                this.opt.sort_by = this.query.sort_by;
            }
            if (this.query.sort_order) {
                this.opt.sort_order = this.query.sort_order;
            }
            this.opt.k = this.$route.query.k;
            this.opt.style_no = [this.$route.query.style_no];
            if (this.$route.query.stat==6) {
                this.opt.stat = [6,11];
            } else if (this.$route.query.stat==1) {
                this.opt.stat = [0,1];
            } else {
                this.opt.stat = [this.$route.query.stat];
            }
            this.opt.page=parseInt(this.$route.query.page);
            this.refreshItems();
            this.$nextTick(()=>{
                this.pageShow = true;
            })
        },
        mounted() {},
        methods: {
            goto(path){
                this.$router.push(path);
            },
            gotoDetail(id) {
                this.$router.push("/factory/market/series/detail/"+id+"?type=1");
            },
            currentChange(e) { // 监听分页
                this.opt.page = e;
                this.$router.push({
                    query:{ 
                        type: this.$route.query.type, 
                        k: this.$route.query.k,
                        style_no: this.$route.query.style_no,
                        stat: this.$route.query.stat,
                        page:e,
                    }
                });
                this.$nextTick(()=>{
                    this.pageShow = true;
                })
                this.refreshItems();
            },
            refreshItems() {
                console.log('refreshItems');
                const _loading = this.$loading({
                    lock: true,
                    text: '拼命加载中......',
                    target:this.$refs.load
                });
                designSetOrder(this.opt).then(rst => {
                    this.total=rst.total;
                    this.list = rst.list;
                    console.log(this.list);
                    _loading.close();
                }).catch(err => {
                    _loading.close();
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .item-plan>>> .el-step__icon.is-icon,.el-step__icon {
        width: unset;
    }
</style>
<style scoped lang="less">
.box {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    overflow: hidden;
}
.box-content {
    width: 100%;
    height: calc(100% - 20px);
    background: #fff;
}
.list {
    // padding: 0 20px;
    padding: 20px;
}
.list-item {
    font-size: 14px;
    color: #808080;
    border: 1px solid #E6E6E6;
    margin-bottom: 20px;
    .list-item-top {
        padding: 20px;
        border-bottom:1px solid rgba(204,204,204,1);
    }
    .list-item-bottom {
        padding: 20px;
    }
    .lit-right {
        min-width: 320px;
        position: relative;
    }
    .item-name {
        font-size: 16px;
        color: #292929;
        line-height: 24px;
        margin-bottom: 8px;
        font-weight: bold;
    }
    .item-series {
        font-size: 12px;
        color: #B3B3B3;
        line-height: 16px;
        margin-bottom: 8px;
    }
    .item-time {
        font-size: 14px;
        color: #808080;
        margin-bottom: 8px;
    }
    .item-price {
        font-size: 14px;
        color: #808080;
        line-height: 32px;
        .price {
            font-size: 16px;
            color: #F66F6A;
            span {
                font-size: 24px;
            }
        }
    }
    .item-avatar {
        padding: 12px 0;
        border-top: 1px solid #E6E6E6;
        margin-top: 12px;
        font-size: 14px;
        color: #292929;
        .avatar {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 6px;
        }
    }
    .item-plan {
        margin: 9px 0 0 25px;
    }
}
.pagination-wrap {
    padding:8px 55px;
    text-align:center;
}
</style>